/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AlgoResult",
            "endpoint": "https://w1l6ctxai3.execute-api.ap-southeast-1.amazonaws.com/lvuaacdc",
            "region": "ap-southeast-1"
        },
        {
            "name": "S3STS",
            "endpoint": "https://aqiz3mjw2l.execute-api.ap-southeast-1.amazonaws.com/lvuaacdc",
            "region": "ap-southeast-1"
        },
        {
            "name": "tokens",
            "endpoint": "https://t8gmzcw1dh.execute-api.ap-southeast-1.amazonaws.com/lvuaacdc",
            "region": "ap-southeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://rvk4brkzcjcvjhqimkqiafrwyi.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6hucctfurnfipo66aq4qt3bd4y",
    "aws_cognito_identity_pool_id": "ap-southeast-1:f1686106-43c3-4999-a324-200f80c509d5",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_UyjP6QbNw",
    "aws_user_pools_web_client_id": "j8diped2grbjicatsen534cbt",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "liveviewereachmin93657-lvuaacdc",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
